import React, { useState, useEffect, useRef } from "react";
import KittenCard from "../kittens/KittenCard.jsx";
import EmptyKittenCard from "../kittens/EmptyKittenCard.jsx";
import KittenColorPicker from "../kittens/KittenColorPicker.jsx";
import EcontCitySelect from "../shop/EcontCitySelect.jsx";
import i18n from "../i18n";

const KittenList = (props) => {
	const { locale } = props;

	const [page, setPage] = useState(1);
	const [kittens, setKittens] = useState([]);
	const [cityFilter, setCityFilter] = useState({});
	const [filteredColors, setFilteredColors] = useState([]);
	// const [filteredKittens, setFilteredKittens] = useState([])

	useEffect(() => {
	  fetchKittens()
	}, [page]);

//	useEffect(() => {
//	  let kitten_list = [];
//		if (filteredColors.length == 0) {
//			kitten_list = kittens;
//		} else {
//			kitten_list = kittens?.filter((kitten) => kitten?.colors.some(c => filteredColors.includes(c)))
//		}
//		if (cityFilter.value) {
//      kitten_list = kitten_list.filter((kitten) => kitten?.city?.id == cityFilter.value);
//    }
//		setFilteredKittens(kitten_list)
//	}, [filteredColors, kittens, cityFilter]);

	const applyFilters = () => {
	  if (!cityFilter.value && filteredColors.length === 0) {
	    return kittens;
	  }

	  let filtered = kittens;
		
	  if (cityFilter.value) {
	    filtered = kittens.filter((kitten) => kitten?.city?.id === cityFilter.value);
	  }

	  if (filteredColors.length > 0) {
	    let filteredByColors = [];
		  
	    filtered?.forEach((kitten) => {
	      if (kittens.some(k => k === kitten.identifier)) {
	        return
	      }
	      if (kitten?.colors.some(c => filteredColors.includes(c))) {
		filteredByColors.push(kitten)
	      }
	    });
		  
	    return filteredByColors;
	  }
	
	  return filtered;
	}

	// this is needed so that it loads the right
	const locale_path = locale == 'de' ? "/de/" : "/";

	const fetchKittens = () => {
	  let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    	  return fetch(`${locale_path}kittens/async_load?page=${page}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json;",
              "X-CSRF-Token": csrf,
            },
          })
          .then((response) => response.json())
          .then((data) => {
      	    let parsed_kittens = data.map(k => JSON.parse(k))
            setKittens([...kittens, ...parsed_kittens])
	    if (data.length == 10) {
              setPage(page + 1)
            }
          })
          .catch((error) => {});
	}
	
  	return (
    	  <>
           <div className="grid-x grid-margin-x">
		    <div className="cell large-4 medium-6">
		    	<EcontCitySelect handleEcontCitySelectChange={setCityFilter} />
		    </div>
		    <div className="cell large-4 medium-6">
		      <KittenColorPicker setFilteredColors={setFilteredColors} />
		    </div>
	    </div>
	    { kittens.length == 0 ? (
		    <div className="grid-x grid-margin-x align-middle">
		    	<EmptyKittenCard />
		    	<EmptyKittenCard />
		    	<EmptyKittenCard />
		    	<EmptyKittenCard />
		    	<EmptyKittenCard />
		    	<EmptyKittenCard />
	    	</div>
	    	) : (
	    		<div className="grid-x grid-margin-x align-middle" id="kitten-list-grid">
	    			{ applyFilters().map((kitten, index) => <KittenCard kitten={kitten} key={index + kitten.identifier} locale={locale} locale_path={locale_path} />)}
     			</div>
	     		)
	    }
	    
    </>
  );
};

export default KittenList;
