import React, { useState, useEffect } from "react";

const DashboardPostForm = (props) => {
  const [loading, setLoading] = useState(false)
	const [selectedSlot, setSelectedSlot] = useState("");
  const [jsonKitten, setJsonKitten] = useState(JSON.parse(props.kitten));
  
  const [imagesAknowedgement, setImagesAknowedgement] = useState(false)
  const [imgs, setImgs] = useState([])

  const [description, setDescription] = useState(jsonKitten.post_description)

  // we use this to show some guidance info to the user 
  const [descriptionAknowedgement, setDescriptionAknowedgement] = useState(false)
  const [descriptionAreaHeight, setDescriptionAreHeight] = useState("1")
		

  useEffect(() => {
    if (descriptionAknowedgement == true) {
      setDescriptionAreHeight(document.getElementById("post_description").scrollHeight)
    }
  },[descriptionAknowedgement])

  const handleSlotSelection = (day, slot) => {
    if (slot.available == false) {
      return
    }

    setSelectedSlot(slotToState(day, slot))
  }

  const slotToState = (day, slot) => {
    return `${day}@${slot.id}` 
  }

  const setSelectedClass = (result) => {
    return result == true ? 'selected' : ""
  }

  const handleImageSelection = (id) => {
    if (imgs.includes(id)) {
      // If the string already exists in the list, remove it
      setImgs(imgs.filter(item => item !== id));
    } else {
      // If the string doesn't exist in the list, add it
      setImgs([...imgs, id]);
    }
  }

  const isValid = () => {
    return description != "" && imgs != [] && selectedSlot != ""
  }

  const submitPost = () => {

      if (isValid() == false) {
        alert('Грешка: Липсваща информация.')
        return
      }

      setLoading(true);
      let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
      return fetch(`/dashboard/posts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;",
          "X-CSRF-Token": csrf,
        },
        body: JSON.stringify({
          post: {
            identifier: jsonKitten.identifier,
            images: imgs,
            description: description,
            slot: selectedSlot
          }
        })
      }).then((res) => {
        if (res.status == 200) {
          res.json().then(json => {
            window.location.href = "/dashboard/posts"
          });
          setLoading(false);
      } else {
        res.json().then(json => {
          alert('Грешка.')
        });
        setLoading(false);
      }
    })
  };
  console.log(imgs)
  return (
    <div>
      <h6>Описание</h6>

      {!descriptionAknowedgement ? (
        <div className="callout tertiary">
          <p>Описанието на социаления ти пост трябва да предоставят синтезирана информация за котето. За удобство, зареждаме основното описание на котето, но моля редактирай и се увери, че отговаря на следните условия:</p>
            <ul>
              <li>описанието не трябва да е по-дълго от няколко изречения;</li>
              <li>не е написано от първо лице;</li>
              <li>липсват граматически грешки;</li>
              <li>не са премахнати автоматично добавените редове с град и линк към публикацията;</li>
            </ul>
          <p>След избиране на бутона Разбирам ще може да редактираш директно описанието на публикацията тук. Не е нужно да редактираш основното описанието на котето.</p>
          <button className="button" onClick={() => setDescriptionAknowedgement(true)}>Разбирам</button>
        </div>
      ) : (
        <textarea style={{height: descriptionAreaHeight, overflow: 'hidden'}} id="post_description" defaultValue={description} onChange={(e) => setDescription(e.target.value)}></textarea>
      )}



      <h6>Снимки</h6>
      {!imagesAknowedgement ? (
        <div className="callout tertiary">
          <p>Снимките са изключително важни за успеха на твоя социален пост. Избери снимки, които са естетични и консистенти една с друга.</p>
          <button className="button" onClick={() => setImagesAknowedgement(true)}>Разбирам</button>
        </div>
      ) : (
      <div className="grid-x grid-margin-x">
        {jsonKitten.parsed_images.map((img) =>(
          <div className={`cell post-image-selector small-3 medium-2 relative ${imgs.includes(img.id)}`}>
            <div className="post-image-selector--wrapper relative">
              <i className="fa-solid fa-circle-check"></i>
              <img src={`${img.url}`} className={`radius`} onClick={() => handleImageSelection(img.id)} />
            </div>
          </div>
        ))}
      </div>
      )}
      <h6>График</h6>
    	<div className="grid-x">
    		{
          props.slots.map((slot) => (
            <div className="cell slot-cell">
              {slot.day}<br/>
              {slot.slots.map((day_slot) =>(
                <button onClick={() => handleSlotSelection(slot.day, day_slot)} className={`slot-button ${day_slot.available} ${setSelectedClass(selectedSlot == slotToState(slot.day, day_slot))}`} >
                  {day_slot.name}
                </button>
              ))}
            </div>
          ))  
        }
    	</div>

    	<button className={`button ${isValid() == false ? 'disabled' : ""}`} onClick={() => submitPost()}>Публикувай</button>
    </div>
  )
}

export default DashboardPostForm;