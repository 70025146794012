import React, { useState, useEffect, useRef } from "react";
import i18n from "../i18n";

const KittenCard = (props) => {
    const { kitten, locale, locale_path } = props;

    const genderIcon = gender => {
    	let icon = "";
    	if (gender == 'male') {
    		icon = 'fa-mars';
    	} else if (gender == 'female') {
    		icon = 'fa-venus';
    	} else if (gender == 'multiple') {
    		icon = 'fa-mars-and-venus';
    	} else {
    		icon = 'fa-question';
    	}
    	return `${gender} ${icon}`
    }
	  return (
	  	<div className={`cell kitten-card-cell large-4 medium-6 ${kitten.esthetique == true ? 'small-6' : 'small-6'} text-center`}>
		  	<a href={`${locale_path}kittens/${kitten.identifier}`} className="kitten-card--link" >
			    <div className="kitten-card">
			    	<div className="kitten-card--image skeleton-placeholder--image" style={{backgroundImage: `url(${kitten.main_image_url}), url('/placeholder-circle.png')`}}>
			    	</div>
			     
						<div className="callout small-padding tertiary no-margin radius-large-bottom hide-for-small-only">
						  <div className="grid-x align-middle">
						    
				        <div className="cell medium-6 small-6 text-left kitten-card--info">
						      <span className="global-weight-bold kitten-card--age">
						      	<i className="fa-light fa-clock gray"></i>
						      	<span className="kitten-card--prop-unit">{kitten.age_unit}</span>
						      	<span className="hide-for-small-only">&nbsp;</span>
						      	<span className="kitten-card--prop-label hide-for-small-only">{kitten.age_measure}</span>
						      	<span className="kitten-card--prop-label show-for-small-only">{kitten.age_measure[0]}.</span>
					      	</span>
						    </div>

						    <div className="cell medium-6 small-6 text-right kitten-card--info">
					        <span className="capitalized global-weight-bold">
					          <span className="kitten-card--prop-unit"><i className={`fa-solid ${genderIcon(kitten.gender)}`}></i>&nbsp;</span>
					        </span>
				        </div>
						    { locale == 'bg' ? (
						    <div className="cell text-left kitten-card--info-secondary">
						    	<i className="fa-light fa-location-dot gray"></i>{kitten.translated_location}
						    </div>
						    ) : ""}

						  </div>
						</div>
					</div>
				</a>
		  </div>
  );
};

export default KittenCard;
